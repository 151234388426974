import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ModalPop from "../components/modal/modal"

const ServicesPagePremium = () => (
	<div className="modal-hero">
		<div className="d-sm-flex justify-content-center package ">
			<div
				data-aos="fade"
				data-aos-delay="600"
				className="package_type shadow aos-init"
			>
				<h3 className="gradient px-4 px-lg-5 py-3">Special</h3>
				<ul className="list-unstyled styled-list text-darker">
					<li>Tube Cleaning</li>
					<li>Weld Services</li>
					<li>Plant Outages</li>
					<li>
						Heat Exchanger Services of all types including plate and frame
					</li>
					<li>Heat Exchanger Pressure testing</li>
					<li>Field-Machine Pipe Bevel Services</li>
					<li>Stub Removal</li>
					<li>Flange Facing</li>
					<li>Tube replacment</li>
					<li>Emergency Tube Plugging</li>
					<li>Pipe Services</li>
					<li>Boiler Rental</li>
					<li>Confined Space Services</li>
					<li>Ancillary Consulting</li>
				</ul>
			</div>
			<div className="text-center mb-4">
				<ModalPop />
			</div>
		</div>
	</div>
)

export default ServicesPagePremium
